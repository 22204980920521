import _ from "lodash";
import { MapKeyProcedureCedulas } from "./mapKeyProcedureCedulas";
import { MapKeyProcedureDDV } from "./mapKeyProcedureDDv";
import { MapKeyProcedureDominio } from "./mapKeyProcedureDominio";
const included = ["terms-uses", "privacy-policy"];

export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

export function isPermission(child, permission) {
  if (included.filter((item) => item === child.key).length > 0) {
    return true;
  }

  const canIsAccess = permission.filter((item) => item.key === child.key);

  if (
    canIsAccess.length > 0 &&
    (canIsAccess[0].canAll || canIsAccess[0].canRead)
  ) {
    return true;
  }

  return false;
}

export function canPermission(entry, permissions, canPermission) {
  console.log(canPermission);
  const result = permissions.filter((item) => item.key === entry);
  const permission = result.length ? result[0] : null;
  if (permission && permission.canAll) {
    return true;
  }

  if (permission && permission[canPermission]) {
    return true;
  }

  return false;
}

export function mapProcedure(payload, service = "forms-cedulas") {
  const mapKeyValue = [];
  if (payload) {
    Object.keys(payload).forEach(function(key) {
      const methodMap = getMapDataWithServiceType(service);
      const result = methodMap(payload[key]);

      mapKeyValue.push(result);
    });

    return _.flatten(mapKeyValue);
  }

  return mapKeyValue;
}

function getMapDataWithServiceType(service) {
  if (service === "forms-cedulas") {
    return MapKeyProcedureCedulas;
  }

  if (service === "forms-denuncia-ventas") {
    return MapKeyProcedureDDV;
  }

  if (service === "forms-informe-dominio") {
    return MapKeyProcedureDominio;
  }
}

export function assetPath(path) {
  return `${process.env.REACT_APP_ASSETS_PATH}/${path}`;
}
