import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import { isPermission } from "../../../app/utils";

export default class MenuList extends React.Component {
  state = {
    permissions: [],
  };

  componentDidMount() {
    this.setState({
      permissions: JSON.parse(localStorage.getItem("PERMISSIONS")),
    });
  }
  render() {
    const { currentUrl, menuConfig, layoutConfig } = this.props;
    return menuConfig.aside.items.map((child, index) => {
      return (
        <React.Fragment key={`menuList${index}`}>
          {child.section &&
            this.state.permissions.length > 0 &&
            isPermission(child, this.state.permissions) && (
              <MenuSection item={child} />
            )}
          {child.separator && <MenuItemSeparator item={child} />}
          {child.title && isPermission(child, this.state.permissions) && (
            <MenuItem
              item={child}
              currentUrl={currentUrl}
              layoutConfig={layoutConfig}
              permissions={
                this.state.permissions.length > 0 ? this.state.permissions : []
              }
            />
          )}
        </React.Fragment>
      );
    });
  }
}
