import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";

const GoogleMaterialPage = lazy(() =>
  import("./google-material/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);

const GuidePage = lazy(() => import("./guides/Guide"));

const ServicePage = lazy(() => import("./services/Service"));

const ProfessionPage = lazy(() => import("./professions/Profession"));

const FaqPage = lazy(() => import("./faqs/Faq"));

const SitesPage = lazy(() => import("./sites/SitesPage"));

const ProceduresPage = lazy(() => import("./procedures/ProceduresPage"));

const UsersPage = lazy(() => import("./users/User"))

const RolesPage = lazy(() => import("./roles/Roles"))

export default function HomePage() {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/services" component={ServicePage} />
        <Route path="/guides" component={GuidePage} />
        <Route path="/professionals" component={ProfessionPage} />
        <Route path="/faqs" component={FaqPage} />
        <Route path="/sites" component={SitesPage} />
        <Route path="/docs" component={DocsPage} />
        <Route path="/procedures" component={ProceduresPage} />
        <Route path="/users" component={UsersPage} />
        <Route path="/roles" component={RolesPage} />
      </Switch>
    </Suspense>
  );
}
