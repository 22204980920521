import moment from 'moment'
function isValidDate(value) {
    const date = new Date(value)
    return date instanceof Date && !isNaN(date);
}
export function MapKeyProcedureDDV(object) {
  const mapToKey = [];

  Object.keys(object).forEach(function(key) {
    mapToKey.push({
      title: keyDDV().find((item) => item.key === key)
        ? keyDDV().find((item) => item.key === key).value
        : "",
      value: mapValueProduct(object[key], key),
    });
  });

  return mapToKey;
}

const mapValueProduct = (keyProduct, key) => {
  const list = [
    {
      key: "1",
      value: "DNI",
    },
    {
      key: "2",
      value: "Libreta de Enrolamiento",
    },
    {
      key: "3",
      value: "Libreta civica",
    },
    {
      key: "4",
      value: "DNI Extranjero",
    },
    {
      key: "5",
      value: "Cedula de identidad extranjero",
    },
    {
      key: "6",
      value: "Pasaporte",
    },
    {
      key: "7",
      value: "CUIT/CUIL",
    }
  ];

  const result = list.find((item) => item.key === keyProduct);

  if(isValidDate(keyProduct) && !result && key !== 'phoneArea'){
      const date = new Date(keyProduct)
      return moment(date).format('DD/MM/YYYY')
  } 

  return result ? result.value : keyProduct;
};



const keyDDV = () => {
  return [
    {
      key: "patente",
      value: "Patente",
    },
    {
      key: "email",
      value: "Email",
    },
    {
      key: "product",
      value: "Tipo de Trámite",
    },
    {
      key: "caracter",
      value: "Caracter del solicitante",
    },
    {
      key: "cuit",
      value: "CUIT",
    },
    {
      key: "fullName",
      value: "Nombre Completo",
    },
    {
      key: "personDniTypeTitular",
      value: "Tipo de Documento",
    },
    {
      key: "personDniTypeTitular",
      value: "Tipo de Documento",
    },
    {
      key: "personDniValueTitular",
      value: "Nro. Documento",
    },
    {
      key: "personTypeTitular",
      value: "Tipo de Persona",
    },
    {
        key: "personTypeComprador", 
        value: "Tipo de Persona Comprador"
    },
    {
        key: "personDniTypeTitular",
        value: "Tipo DNI titular"
    },
    {
        key: "personDniValueTitular",
        value: "Número titular"
    },
    {
        key: "personDniTypeComprador",
        value: "Tipo DNI comprador"
    },
    {
        key: "personDniValueComprador",
        value: "Número comprador"
    },
    {
        key: "nameComprador",
        value: "Nombre Comprador"
    },
    {
        key: "lastNameComprador",
        value: "Apellido Comprador"
    },
    {
        key: "salePlace",
        value: "Lugar de compra"
    },
    {
        key: "saleDate",
        value: "Fecha de la Venta"
    },
    {
      key: "turnDate",
      value: "Fecha de Turno",
    },
    {
      key: "docTypeLabel",
      value:"Tipo de Documento"
    },
    {
      key: "docValue",
      value: "Nro"
    },
    {
      key: "firstName",
      value: "Nombre"
    }, 
    {
      key: "lastName",
      value: "Apellido"
    },
    {
        key: "modelo",
        value: "Modelo"
    },
    {
        key: "phone",
        value: "Teléfono"
    },
    {
        key: "phoneArea",
        value: "Código de Area"
    },
    {
        key: "phoneNumber",
        value: "Número"
    }, 
    {
        key: "phoneOperator",
        value: "Operador"
    }
  ];
};
