import moment from 'moment'
function isValidDate(value) {
    const date = new Date(value)
    return date instanceof Date && !isNaN(date);
}
export function MapKeyProcedureDominio(object) {
  const mapToKey = [];

  Object.keys(object).forEach(function(key) {
    mapToKey.push({
      title: keyDominio().find((item) => item.key === key)
        ? keyDominio().find((item) => item.key === key).value
        : "",
      value: mapValueProduct(object[key], key),
    });
  });

  return mapToKey;
}

const mapValueProduct = (keyProduct, key) => {
  const list = [
    {
      key: "1",
      value: "DNI",
    },
    {
      key: "2",
      value: "Libreta de Enrolamiento",
    },
    {
      key: "3",
      value: "Libreta civica",
    },
    {
      key: "4",
      value: "DNI Extranjero",
    },
    {
      key: "5",
      value: "Cedula de identidad extranjero",
    },
    {
      key: "6",
      value: "Pasaporte",
    },
    {
      key: "7",
      value: "CUIT/CUIL",
    }
  ];

  const result = list.find((item) => item.key === keyProduct);

  if(isValidDate(keyProduct) && !result && key !== 'phoneArea'){
      const date = new Date(keyProduct)
      return moment(date).format('DD/MM/YYYY')
  } 

  return result ? result.value : keyProduct;
};



const keyDominio = () => {
  return [
    {
      key: "patente",
      value: "Patente",
    },
    {
      key: "email",
      value: "Email",
    },
    {
      key: "typeVehicle",
      value: "Tipo de Vehiculo",
    },
    {
      key: "cuit",
      value: "CUIT",
    }
  ];
};
