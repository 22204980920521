
import moment from 'moment'
function isValidDate(value) {
    const date = new Date(value)
    return date instanceof Date && !isNaN(date);
}
export function MapKeyProcedureCedulas(object) {
  const mapToKey = [];

  Object.keys(object).forEach(function(key) {
    mapToKey.push({
      title: keyCedulas().find((item) => item.key === key)
        ? keyCedulas().find((item) => item.key === key).value
        : "",
      value: mapValueProduct(object[key]),
    });
  });

  return mapToKey;
}

const mapValueProduct = (keyProduct) => {
  const list = [
    {
      key: "1",
      value: "DNI",
    },
    {
      key: "2",
      value: "Libreta de Enrolamiento",
    },
    {
      key: "3",
      value: "Libreta civica",
    },
    {
      key: "4",
      value: "DNI Extranjero",
    },
    {
      key: "5",
      value: "Cedula de identidad extranjero",
    },
    {
      key: "6",
      value: "Pasaporte",
    },
    {
      key: "7",
      value: "CUIT/CUIL",
    },
    {
      key: "cedula-azul",
      value: "Solicitar Nueva Cédula Azul",
    },
    {
      key: "revocacion-cedula-azul",
      value: "Dar de baja una Cédula Azul",
    },
    {
      key: "renovate-cedula-verde",
      value: "Renovar Cédula Verde por vencimiento",
    },
    {
      key: "cedula-verde",
      value: "Solicitar una Cédula Verde adicional",
    },
    {
      key: "duplicate-cedula-verde",
      value: "Solicitar duplicado de cédula verde",
    },
  ];

  const result = list.find((item) => item.key === keyProduct);

  if(isValidDate(keyProduct) && !result){
      const date = new Date(keyProduct)
      return moment(date).format('DD/MM/YYYY')
  } 

  return result ? result.value : keyProduct;
};



const keyCedulas = () => {
  return [
    {
      key: "patente",
      value: "Patente",
    },
    {
      key: "email",
      value: "Email",
    },
    {
      key: "product",
      value: "Tipo de Trámite",
    },
    {
      key: "caracter",
      value: "Caracter del solicitante",
    },
    {
      key: "cuit",
      value: "CUIT",
    },
    {
      key: "fullName",
      value: "Nombre Completo",
    },
    {
      key: "personDniTypeTitular",
      value: "Tipo de Documento",
    },
    {
      key: "personDniTypeTitular",
      value: "Tipo de Documento",
    },
    {
      key: "personDniValueTitular",
      value: "Nro. Documento",
    },
    {
      key: "personTypeTitular",
      value: "Tipo de Persona",
    },
    {
      key: "cedulas",
      value: "Cedulas Solicitadas",
    },
    {
      key: "turnDate",
      value: "Fecha de Turno",
    },
    {
      key: "docTypeLabel",
      value:"Tipo de Documento"
    },
    {
      key: "docValue",
      value: "Nro"
    },
    {
      key: "firstName",
      value: "Nombre"
    }, 
    {
      key: "lastName",
      value: "Apellido"
    }
  ];
};
